<template>
  <nav class="relative img">
    img
    <ul class="dropdown flex flex-col justify-center">
      <li class="text">
        menu1
      </li>
      <li class="text">
        menu2
      </li>
      <li class="text">
        menu3
      </li>
      <li class="text">
        menu4
      </li>
    </ul>
  </nav>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img {
  &:hover {
    .dropdown {
      height: 10.5rem;
      background-color: #3862a0;
    }
  }

  .text {
    height: 2rem;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: #fff;
  }
}

.dropdown {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;

  width: 8rem;
  height: 0;
  border-radius: 0.5rem;
  // line-height: 40px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.1s;
}
</style>
